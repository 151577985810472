@use '../../scss/' as *;

.absolute {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
}

.mark-page1 { z-index: 1; }
.mark-page2 { z-index: 2; }
.mark-page3 { z-index: 3; }
.mark-page4 { z-index: 4; }
.mark-page5 { z-index: 5; }

.full-width {
    width: 100%; /* Adjust this value if necessary */
}

.heading {
    font-size: 5em; /* Adjust as needed */
    line-height: 1.2em; /* Adjust as needed */
}